<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="primary d-none d-md-flex align-center justify-center backgroundImg"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div class="d-flex justify-center">
                <img src="@/assets/images/logos/logo-vumetric-telus-white.svg" alt="vumetric logo">
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  class="mt-4 text-capitalize"
                  x-large
                  outlined
                  color="white"
                  @click="goToCorporateWebsite"
                >
                  Learn More
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col v-if="tokenId !== null" cols="12" lg="9" xl="6">
              <h2 class="font-weight-bold mt-4">
                Identity verification
              </h2>
              <h6 class="subtitle-1">
                Enter 6-digits code from your cell phone
              </h6>

              <v-form ref="formOTP" v-model="validOTP">
                <v-otp-input
                  v-model="otp"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="PIN"
                  class="mt-4"
                  autofocus
                  required
                  outlined
                  @finish="submitPIN"
                />

                <v-btn
                  :disabled="!valid"
                  depressed
                  color="primary mt-6"
                  block
                  class="mr-4"
                  @click="submitPIN"
                >
                  Send
                </v-btn>
              </v-form>
            </v-col>
            <v-col v-if="tokenId === null" cols="12" lg="9" xl="6">
              <h2 class="font-weight-bold mt-4">
                Sign in
              </h2>
              <v-row justify="center" align="center" class="mt-6">
                <v-col cols="6">
                  <v-btn
                    large
                    block
                    outlined
                    text
                    class="text-capitalize"
                    @click="ssoConnection('azure')"
                  >
                    <img
                      class="mr-2"
                      src="@/assets/images/logos/microsoft_logo.svg"
                      alt="microsoft logo"
                    >
                    Microsoft
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    large
                    block
                    outlined
                    text
                    class="text-capitalize"
                    @click="ssoConnection('google')"
                  >
                    <img
                      class="mr-2"
                      src="@/assets/images/logos/google_logo.svg"
                      alt="google logo"
                    >
                    Google
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="text-center font-weight-thin my-5 text--grey">
                <v-col cols="4" class="pt-0">
                  <v-divider class="ml-4 mt-3" />
                </v-col>
                <v-col cols="4" class="px-0 pt-0">
                  <div class="pa-0">Or sign in with</div>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-divider class="mr-4  mt-3" />
                </v-col>
              </v-row>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="user.username"
                  label="E-mail"
                  class="mt-4"
                  :rules="[rules.email]"
                  required
                  outlined
                  @keyup.enter.prevent="handleLogin()"
                />
                <v-text-field
                  v-model="user.password"
                  :type="valuePW ? 'password' : 'text'"
                  label="Password"
                  required
                  outlined
                  @keyup.enter.prevent="handleLogin()"
                >
                  <v-icon
                    slot="append"
                    color="primary"
                    @click="valuePW = !valuePW"
                  >
                    {{ valuePW ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </v-text-field>

                <div class="d-block d-sm-flex align-center justify-space-between mb-4 mb-sm-0">
                  <v-checkbox
                    v-model="rememberMe"
                    label="Remember Me"
                    text
                    color="primary"
                    class="mb-4"
                  />
                  <div class="mb-4">
                    <v-btn text to="forgot" color="primary" class="link">
                      Forgot password?
                    </v-btn>
                  </div>
                </div>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  block
                  large
                  class="mr-4"
                  submit
                  elevation="0"
                  @click="handleLogin"
                >
                  Sign In
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <snack-bar />
  </v-row>
</template>

<script>
import User from '@/models/user'
import UserService from '@/services/user.service'
import SnackBar from '@/components/base/SnackBar'
import AuthService from '@/services/auth.service'
import snackbarPlugin from '@/plugins/snackbar'

export default {
  components: {
    SnackBar
  },
  data: () => ({
    user: new User('', ''),
    valid: false,
    validOTP: false,
    tokenId: null,
    rememberMe: false,
    message: '',
    otp: '',
    valuePW: String,
    rules: {
      required: (value) => !!value || 'Required',
      counter: (value) => value.length <= 20 || 'Max 20 characters',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\. {2}[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail'
      }
    }
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/dashboard')
    }
    this.rememberMe = localStorage.pinToken && localStorage.pinToken !== 'null'
  },
  methods: {
    async ssoConnection(provider) {
      const res = await AuthService.ssoLogin(provider)
      window.location.href = res.authUrl
    },
    goToCorporateWebsite() {
      window.location.href = ('https://www.vumetric.com')
    },
    submitPIN() {
      if (this.$refs.formOTP.validate()) {
        this.$store.dispatch('auth/login2FA', {
          rememberMe: this.rememberMe,
          pin: this.otp,
          id: this.tokenId
        }).then(
          (resp) => {
            UserService.getProfile().then((profile) => {
              this.$store.commit('auth/setProfile', profile)
              // this.$router.push('/dashboard')
              setTimeout(() => { this.$router.go() }, 700)
            })
          },
          (error) => {
            if (error.response.status === 400) {
              snackbarPlugin.showError(error.response.data.detail)
            }
          }
        )
      }
    },
    handleLogin() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Connecting ...')
        if (this.rememberMe === false) {
          localStorage.removeItem('pinToken')
        }
        if (localStorage.pinToken && localStorage.pinToken !== 'null') {
          this.$store.dispatch('auth/login', this.user).then(
            (resp) => {
              snackbarPlugin.showSuccess('Connected')
              if (resp.id) {
                this.tokenId = resp.id
              } else {
                UserService.getProfile().then((profile) => {
                  this.$store.commit('auth/setProfile', profile)
                  this.$router.push('/dashboard')
                  this.$router.go()
                })
              }
            },
            (error) => {}
          )
        } else {
          this.$store.dispatch('auth/login', this.user).then(
            (resp) => {
              snackbarPlugin.showSuccess('Connected')
              if (resp.id) {
                this.tokenId = resp.id
              } else {
                UserService.getProfile().then((profile) => {
                  this.$store.commit('auth/setProfile', profile)
                  this.$router.push('/dashboard')
                })
              }
            },
            (error) => {}
          )
        }
      }
    }
  }
}
</script>

<style lang="scss">
.backgroundImg {
  background-image: url('../../assets/images/background-hex.png');
}
</style>
